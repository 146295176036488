import axiosClient from '../utils/axios';

export const refetchCart = async (params: any) => {
  const res = await axiosClient.get('/client/cart/list', {
    params,
  });
  return res.data;
};

export const updateCart = async (body: any) => {
  const res = await axiosClient.patch(`/client/cart/update`, body);
  return res.data;
};

export const replaceCart = async (body: any) => {
  const res = await axiosClient.post(`/client/cart/replace`, body);
  return res.data;
};

export const clearCart = () => {
  return axiosClient.get(`/client/cart/clear`);
};
