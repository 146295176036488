import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
  chakra,
  shouldForwardProp,
  useToast,
} from '@chakra-ui/react';
import { AnimatePresence, isValidMotionProp, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import {
  FiFileText,
  FiMinus,
  FiPlus,
  FiShoppingCart,
  FiTrash,
  FiX,
} from 'react-icons/fi';
import { MdOutlineRemoveShoppingCart } from 'react-icons/md';
import { useAuth } from '@/hooks/AuthContext';
import { useCart } from '@/hooks/CartContext';

const MotionStack = chakra(motion.div, {
  shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export default function FloatingCart() {
  const router = useRouter();
  const wrapperRef = useRef(null);
  const {
    cart,
    isCartOpen,
    isCartUpdating,
    setIsCartOpen,
    quoteURL,
    getQuoteForCart,
    clearCart,
  } = useCart();
  const { user } = useAuth();
  const toast = useToast();

  useEffect(() => {
    setIsCartOpen(false);
  }, [router.pathname]);

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      const checkShouldIgnore = (elem: HTMLElement) => {
        if (elem.classList.contains('cart-ignore')) {
          return true;
        }

        if (elem.parentElement) {
          return checkShouldIgnore(elem.parentElement);
        }

        return false;
      };

      if (
        e.target &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target) &&
        !checkShouldIgnore(e.target as HTMLElement)
      ) {
        setIsCartOpen(false);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  return (
    <AnimatePresence>
      {isCartOpen && (
        <MotionStack
          initial={{ opacity: 0, y: 500 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 500 }}
          transition={{ duration: 0.2, ease: 'easeInOut' } as any}
          position='fixed'
          bottom='0'
          right='4'
          gap='0'
          ref={wrapperRef}
          w='96'
          h='lg'
          bg='white'
          boxShadow='lg'
          roundedTop='2xl'
          overflow='hidden'
          border='1px solid'
          borderColor='gray.200'
        >
          <Stack w='96' h='lg'>
            <HStack
              justifyContent='space-between'
              p='3'
              borderBottomWidth='1px'
              borderBottomColor='gray.200'
            >
              {cart.length > 0 && (
                <Box flexShrink='1'>
                  <IconButton
                    aria-label='Clear cart'
                    icon={<MdOutlineRemoveShoppingCart />}
                    onClick={() => clearCart()}
                    colorScheme='red'
                    variant='ghost'
                  />
                </Box>
              )}
              <HStack pl='3' justifyContent='center' gap='0' flexGrow='1'>
                <FiShoppingCart size='16' color='black' />
                <Stack gap='0'>
                  <Box fontWeight='semibold' fontSize='md' ml='2'>
                    Cart
                  </Box>
                </Stack>
              </HStack>
              <Box flexShrink='1'>
                <IconButton
                  aria-label='Close cart'
                  icon={<FiX />}
                  onClick={() => setIsCartOpen(false)}
                  size='md'
                  colorScheme='gray'
                  variant='ghost'
                  color='gray.500'
                />
              </Box>
            </HStack>

            <Stack overflowY='auto' gap='0' flexGrow='1'>
              {cart.map(item => (
                <CartItem key={item.product.id} item={item} />
              ))}
              {cart.length === 0 && (
                <Stack
                  p='3'
                  textAlign='center'
                  color='gray.500'
                  alignItems='center'
                >
                  <Text mt='4'>Your cart is empty</Text>

                  {router.pathname !== '/clients/me/products' && (
                    <Button
                      variant='procura'
                      size='sm'
                      width='min-content'
                      fontSize='sm'
                      mt='2'
                      onClick={() => {
                        router.push('/clients/me/products');
                      }}
                    >
                      Shop now
                    </Button>
                  )}
                </Stack>
              )}
            </Stack>

            {cart.length > 0 && (
              <HStack
                p='3'
                justifyContent='space-between'
                borderTopWidth='1px'
                borderTopColor='gray.200'
                mt='auto'
              >
                <Stack gap='0' pl='4'>
                  <Text fontSize='sm' color='gray.500' fontWeight='medium'>
                    Subtotal
                  </Text>
                  <Text fontSize='md' color='gray.900' fontWeight='semibold'>
                    ₹
                    {cart
                      .reduce(
                        (acc, item) =>
                          acc + item.product.gstRate * item.quantity,
                        0,
                      )
                      .toFixed(2)}
                  </Text>
                </Stack>
                <HStack>
                  {quoteURL.length === 0 && (
                    <Button
                      variant='outline'
                      size='sm'
                      fontSize='sm'
                      py='4'
                      iconSpacing='1'
                      rightIcon={<FiFileText />}
                      ml='auto'
                      onClick={() => getQuoteForCart()}
                      isLoading={isCartUpdating}
                    >
                      Get Quote
                    </Button>
                  )}
                  {quoteURL.length && (
                    <Button
                      variant='outline'
                      size='sm'
                      fontSize='sm'
                      py='4'
                      iconSpacing='1'
                      rightIcon={<FiFileText />}
                      ml='auto'
                      onClick={() => {
                        window.open(quoteURL, '_blank');
                      }}
                    >
                      View Quote
                    </Button>
                  )}
                  <Button
                    variant='procura'
                    size='sm'
                    fontSize='sm'
                    ml='auto'
                    onClick={() => {
                      if (user.role?.canCreateOrder) {
                        router.push('/orders/create');
                      } else if (user.role?.canCreateOrderRequest) {
                        router.push('/order-requests/create');
                      } else {
                        toast({
                          title: 'Permission denied',
                          description:
                            'You do not have permission to create orders or order requests',
                          status: 'error',
                          duration: 5000,
                          isClosable: true,
                        });
                      }
                    }}
                  >
                    Checkout
                  </Button>
                </HStack>
              </HStack>
            )}
          </Stack>
        </MotionStack>
      )}
    </AnimatePresence>
  );
}

const CartItem = ({ item }) => {
  const { isCartUpdating, updateCartItem, cart } = useCart();
  const [quantity, setQuantity] = useState(item.quantity + '');

  useEffect(() => {
    setQuantity(item.quantity + '');
  }, [item]);

  return (
    <Stack
      key={item.product.id}
      p='3'
      borderBottomWidth='1px'
      borderBottomColor='gray.200'
    >
      <HStack>
        <Image
          src={
            item.product?.imageUrl ||
            item.product?.catalogProduct?.images[0]?.url
          }
          alt={item.product.name || item.product?.catalogProduct?.name}
          w='10'
          h='10'
          borderRadius='4'
          objectFit='contain'
          objectPosition='center'
          border='1px solid'
          borderColor='gray.200'
        />
        <Stack gap='0'>
          <Text fontWeight='semibold' fontSize='sm'>
            {item.product.name || item.product?.catalogProduct?.name}
          </Text>
          4
          <Text fontSize='xs' color='gray.500'>
            ₹{item.product.fixedRate} + GST = ₹{item.product.gstRate} / unit
          </Text>
        </Stack>

        <IconButton
          aria-label='Remove from cart'
          icon={<FiTrash />}
          isDisabled={isCartUpdating}
          onClick={() => {
            updateCartItem(item.product.id, 0);
          }}
          size='sm'
          colorScheme='gray'
          variant='ghost'
          color='gray.500'
          ml='auto'
        />
      </HStack>

      <HStack>
        <InputGroup w='24' flexShrink={0}>
          <InputLeftElement>
            <IconButton
              variant='ghost'
              size='xs'
              mt='-2'
              aria-label='Decrement'
              icon={<FiMinus />}
              isDisabled={isCartUpdating}
              onClick={() =>
                updateCartItem(
                  item.product.id,
                  item.quantity - (item.product.minimumOrderQuantity || 1),
                )
              }
            />
          </InputLeftElement>
          <Input
            type='number'
            placeholder='Qty'
            size='sm'
            fontSize='xs'
            borderRadius='4'
            textAlign='center'
            value={quantity}
            isDisabled={isCartUpdating}
            min={1}
            onChange={e => {
              let value = parseInt(e.target.value);

              if (!isNaN(value) && item.product.minimumOrderQuantity) {
                value =
                  Math.ceil(value / item.product.minimumOrderQuantity) *
                  item.product.minimumOrderQuantity;
                setQuantity(value + '');
              } else {
                setQuantity(e.target.value);
              }

              if (value && value > 0) {
                updateCartItem(item.product.id, value);
              }
            }}
          />
          <InputRightElement>
            <IconButton
              variant='ghost'
              size='xs'
              mt='-2'
              aria-label='Increment'
              icon={<FiPlus />}
              isDisabled={isCartUpdating}
              onClick={() =>
                updateCartItem(
                  item.product.id,
                  item.quantity + (item.product.minimumOrderQuantity || 1),
                )
              }
            />
          </InputRightElement>
        </InputGroup>

        <Box color='gray.600' flexShrink={0} fontSize='sm'>
          units
        </Box>

        <Box ml='auto' textAlign={'right'} flexShrink={0}>
          <Text fontSize='md' color='gray.900' fontWeight='medium'>
            ₹{(item.product.gstRate * item.quantity).toFixed(2)}
          </Text>
        </Box>
      </HStack>
    </Stack>
  );
};
