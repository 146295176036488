import moment from 'moment';

export const PROCURA_START_DATE = '2024-04-01';

export const DATE_RANGE_OPTIONS = {
  CUSTOM: null,

  THIS_MONTH: [
    moment().startOf('month').format('YYYY-MM-DD'),
    moment().endOf('month').format('YYYY-MM-DD'),
  ],

  LAST_MONTH: [
    moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
    moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
  ],

  THIS_QUARTER: [
    moment().startOf('quarter').format('YYYY-MM-DD'),
    moment().endOf('quarter').format('YYYY-MM-DD'),
  ],

  LAST_QUARTER: [
    moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
    moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'),
  ],

  // THIS_YEAR: [
  //   moment().startOf('year').format('YYYY-MM-DD'),
  //   moment().endOf('year').format('YYYY-MM-DD'),
  // ],

  // LAST_YEAR: [
  //   moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
  //   moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
  // ],

  THIS_YEAR:
    moment().get('month') > 2
      ? [
          moment().month(3).startOf('month').format('YYYY-MM-DD'),
          moment().add(1, 'year').month(2).endOf('month').format('YYYY-MM-DD'),
        ]
      : [
          moment()
            .subtract(1, 'year')
            .month(3)
            .startOf('month')
            .format('YYYY-MM-DD'),
          moment().month(2).endOf('month').format('YYYY-MM-DD'),
        ],

  LAST_YEAR:
    moment().get('month') > 2
      ? [
          moment()
            .subtract(1, 'year')
            .month(3)
            .startOf('month')
            .format('YYYY-MM-DD'),
          moment().month(2).endOf('month').format('YYYY-MM-DD'),
        ]
      : [
          moment()
            .subtract(2, 'year')
            .month(3)
            .startOf('month')
            .format('YYYY-MM-DD'),
          moment()
            .subtract(1, 'year')
            .month(2)
            .endOf('month')
            .format('YYYY-MM-DD'),
        ],

  SINCE_THE_BEGINNING: [
    moment(PROCURA_START_DATE).format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ],
};

export const UNITS_MASTER = [
  'Units',
  'N.A.',
  'Pcs.',
  'Kgs.',
  'Gms.',
  'Metre',
  'Dozen',
  'Tonne',
  'Feet',
  'Box',
  'Pairs',
  'Litre',
  'Pack',
  'Can',
  'Packet',
  'Rolls',
  'Mililitre',
  'Sqfeet',
  'Bottle',
  'Set',
  'Strip',
  'SQMETRE',
];

export const MATERIAL_CENTRE_OPTIONS = [
  'Bangalore Office',
  'Mumbai Office',
  'Vasai Factory',
];

export const ORDER_CATEGORY_OPTIONS = [
  'HK Monthly Indent',
  'Stationery Monthly Indent',
  'Packaging',
  'Capex',
  'OTS',
  'Waste Management',
  'Others',
];

export const ORDER_COLOR_MAP = {
  PENDING: 'yellow',
  DRAFT: 'yellow',
  CREATED: 'cyan',
  ACCEPTED: 'cyan',
  // IN_PROCESS: 'yellow',
  P_DISPATCHED: 'orange',
  IN_TRANSIT: 'orange',
  // CONFIRMED: 'green',
  P_DELIVERED: 'blue',
  DELIVERED: 'green',
  COMPLETED: 'green',
  CANCELLED: 'red',
};

export const PURCHASE_ORDER_COLOR_MAP = {
  ACCEPTED: 'cyan',
  P_RECEIVED: 'orange',
  RECEIVED: 'green',
  COMPLETED: 'green',
  CANCELLED: 'red',
};

export const ORDER_ITEMS_COLOR_MAP = {
  IN_PROCESS: 'yellow',
  P_DISPATCHED: 'blue',
  DISPATCHED: 'orange',
  DELIVERED: 'green',
};

export const PURCHASE_ORDER_ITEMS_COLOR_MAP = {
  IN_PROCESS: 'yellow',
  P_RECEIVED: 'blue',
  RECEIVED: 'green',
};

export const ORDER_REQUEST_COLOR_MAP = {
  PENDING: 'yellow',
  APPROVED: 'cyan',
  CREATED: 'blue',
  IN_PROGRESS: 'orange',
  COMPLETED: 'green',
  CANCELLED: 'red',
};

export const ADMIN_ROLE_OPTIONS = [
  'ADMIN',
  'SALES_COORDINATOR',
  'PURCHASE_COORDINATOR',
  'DISPATCH_COORDINATOR',
  'SALES_PERSON',
  'ACCOUNTS',
];

export const SOURCE_OPTIONS = [
  'Linkedin',
  'IndiaMart',
  'References',
  'Email Marketing',
  'Corporate CE List',
  'Krishen’s leads',
  'Sajads leads',
];

// prettier-ignore
export const SECTOR_OPTIONS = [
  "BFSI - Banks, Financial Services, Insurance, NBFC",
  "Hotels & Restaurants, Hospitality, Salons",
  "Retails - Multi Outlet Brands",
  "Media, Marketing & Entertainment",
  "Facility Management",
  "Pharmaceutical",
  "Healthcare & Hospitals, Schools & Financial Institution",
  "Real Estate, Coworking Spaces, Contruction & Interiror Projects",
  "Corporate",
  "Information Technology, Software Development, E-commerce",
  "Reseller"
]

export const INTERESTED_CATEGORIES = [
  'Hygiene & Healthcare',
  'One Time Setup/ Capex/ White Goods',
  'Gifting',
  'Stationery & Office Supplies',
  'MEP & MRO',
  'Packaging',
];

export const GATI_TRANSIT_CODES = {
  DCRE: 'Forward Docket Created',
  // Rebooked
  DCRB: 'Rebooked Docket Created',
  REDRTO: 'Marked for Re-booked',
  DRTO: 'Re-booked',
  // Shipment Out
  TCSOU: 'Out from Booking Location',
  TCAER: 'Arrived at Enroute HUB',
  TCSEO: 'Out from Enroute HUB',
  DPDCC: 'Out for Delivery',
  TCADO: 'Arrived at Delivery Location',
  DDPVD: 'Partial Shipment Delivered',
  DKTAD: 'Arrived at Delivery Location',
  // Delivered Status
  DDLVD: 'Delivered',
  DUNDL: 'Undelivered',
  // Reverse Pickup
  NAS: 'Reverse Pickup ready to assign',
  PAC: 'Reverse Pickup Request Pending',
  ACP: 'Reverse Pickup Request is accepted',
  RPOP: 'Reverse Pickup Customer requested for another date for pickup',
  POP: 'Reverse Pickup Pickup postponed for Next day',
  REJ: 'Reverse Pickup Customer refuse to handover shipment',
  CLO: 'Reverse Pickup Shipment received from Shipper',
  RCN: 'Reverse Pickup Request for Cancellation',
  CAN: 'Reverse Pickup Pickup Cancelled',
};

export const GATI_TRACKING_STATUS = [
  'Booked',
  'In Transit',
  'Reached Destination',
  'Delivered',
];

export enum AcquistionFunnelStagesEnum {
  LEAD_QUALIFICATION = 'Lead Qualification',
  INITIAL_CONTACT = 'Initial Contact',
  MEETING_SCHEDULED_OR_MEETING_DONE = 'Meeting Scheduled / Meeting Done',
  RFQ_RECEIVED = 'RFQ Received',
  QUOTATION_SUBMITTED_AND_UNDER_REVIEW = 'Quotation Submitted & Under Review',
  NEGOTIATION_AND_SAMPLING = 'Negotiation & Sampling',
  PRC_ONBOARDING = 'PRC Onboarding',
  DEAL_CLOSED = 'Deal Closed',
  NOT_INTERESTED = 'Not Interested',
  CLIENT_DISMISSED = 'Client Dismissed',
  APPROACH_LATER = 'Approach Later',
}

export const AcquisitionFunnelStepper = [
  {
    label: 'Lead Qualification',
    value: AcquistionFunnelStagesEnum.LEAD_QUALIFICATION,
  },
  {
    label: 'Initial Contact',
    value: AcquistionFunnelStagesEnum.INITIAL_CONTACT,
  },
  {
    label: 'Meeting Scheduled / Meeting Done',
    value: AcquistionFunnelStagesEnum.MEETING_SCHEDULED_OR_MEETING_DONE,
  },
  {
    label: 'RFQ Received',
    value: AcquistionFunnelStagesEnum.RFQ_RECEIVED,
  },
  {
    label: 'Quotation Submitted & Under Review',
    value: AcquistionFunnelStagesEnum.QUOTATION_SUBMITTED_AND_UNDER_REVIEW,
  },
  {
    label: 'Negotiation & Sampling',
    value: AcquistionFunnelStagesEnum.NEGOTIATION_AND_SAMPLING,
  },
  {
    label: 'PRC Onboarding',
    value: AcquistionFunnelStagesEnum.PRC_ONBOARDING,
  },
  {
    label: 'Deal Closed',
    value: AcquistionFunnelStagesEnum.DEAL_CLOSED,
  },
];

export const AcquisitionFunnelOrder = [
  AcquistionFunnelStagesEnum.LEAD_QUALIFICATION,
  AcquistionFunnelStagesEnum.INITIAL_CONTACT,
  AcquistionFunnelStagesEnum.MEETING_SCHEDULED_OR_MEETING_DONE,
  AcquistionFunnelStagesEnum.RFQ_RECEIVED,
  AcquistionFunnelStagesEnum.QUOTATION_SUBMITTED_AND_UNDER_REVIEW,
  AcquistionFunnelStagesEnum.NEGOTIATION_AND_SAMPLING,
  AcquistionFunnelStagesEnum.PRC_ONBOARDING,
  AcquistionFunnelStagesEnum.DEAL_CLOSED,
  AcquistionFunnelStagesEnum.NOT_INTERESTED,
  AcquistionFunnelStagesEnum.APPROACH_LATER,
  AcquistionFunnelStagesEnum.CLIENT_DISMISSED,
];

export const AcquisitionFunnelTransition = {
  [AcquistionFunnelStagesEnum.LEAD_QUALIFICATION]: [
    AcquistionFunnelStagesEnum.INITIAL_CONTACT,
    AcquistionFunnelStagesEnum.NOT_INTERESTED,
    AcquistionFunnelStagesEnum.APPROACH_LATER,
    AcquistionFunnelStagesEnum.CLIENT_DISMISSED,
  ],
  [AcquistionFunnelStagesEnum.INITIAL_CONTACT]: [
    AcquistionFunnelStagesEnum.MEETING_SCHEDULED_OR_MEETING_DONE,
    AcquistionFunnelStagesEnum.NOT_INTERESTED,
    AcquistionFunnelStagesEnum.APPROACH_LATER,
    AcquistionFunnelStagesEnum.CLIENT_DISMISSED,
  ],
  [AcquistionFunnelStagesEnum.MEETING_SCHEDULED_OR_MEETING_DONE]: [
    AcquistionFunnelStagesEnum.RFQ_RECEIVED,
    AcquistionFunnelStagesEnum.NOT_INTERESTED,
    AcquistionFunnelStagesEnum.APPROACH_LATER,
    AcquistionFunnelStagesEnum.CLIENT_DISMISSED,
  ],
  [AcquistionFunnelStagesEnum.RFQ_RECEIVED]: [
    AcquistionFunnelStagesEnum.QUOTATION_SUBMITTED_AND_UNDER_REVIEW,
    AcquistionFunnelStagesEnum.NOT_INTERESTED,
    AcquistionFunnelStagesEnum.APPROACH_LATER,
    AcquistionFunnelStagesEnum.CLIENT_DISMISSED,
  ],
  [AcquistionFunnelStagesEnum.QUOTATION_SUBMITTED_AND_UNDER_REVIEW]: [
    AcquistionFunnelStagesEnum.NEGOTIATION_AND_SAMPLING,
    AcquistionFunnelStagesEnum.NOT_INTERESTED,
    AcquistionFunnelStagesEnum.APPROACH_LATER,
    AcquistionFunnelStagesEnum.CLIENT_DISMISSED,
  ],
  [AcquistionFunnelStagesEnum.NEGOTIATION_AND_SAMPLING]: [
    AcquistionFunnelStagesEnum.PRC_ONBOARDING,
    AcquistionFunnelStagesEnum.NOT_INTERESTED,
    AcquistionFunnelStagesEnum.APPROACH_LATER,
    AcquistionFunnelStagesEnum.CLIENT_DISMISSED,
  ],
  [AcquistionFunnelStagesEnum.PRC_ONBOARDING]: [
    AcquistionFunnelStagesEnum.DEAL_CLOSED,
    AcquistionFunnelStagesEnum.NOT_INTERESTED,
    AcquistionFunnelStagesEnum.APPROACH_LATER,
    AcquistionFunnelStagesEnum.CLIENT_DISMISSED,
  ],
  [AcquistionFunnelStagesEnum.DEAL_CLOSED]: [
    AcquistionFunnelStagesEnum.LEAD_QUALIFICATION,
    AcquistionFunnelStagesEnum.NOT_INTERESTED,
  ],
};

export enum RetentionFunnelStagesEnum {
  EXISTING_CLIENT = 'Existing client',
  REGULAR_CALLS_AND_MEETINGS = 'Regular calls and meetings happening',
  IDENTIFICATION_OF_NEW_BUSINESS = 'Identification of new business',
  NEGOTIATION_AND_SAMPLING_OF_NEW_BUSSINESS = 'Negotiation & Sampling of new business',
  NEW_BUSINESS_FIRST_ORDER_PLACED = 'New business first order placed',
  NOT_INTERESTED = 'Not Interested',
}

export const RetentionFunnelStepper = [
  {
    label: 'Existing client',
    value: RetentionFunnelStagesEnum.EXISTING_CLIENT,
  },
  {
    label: 'Regular calls and meetings happening',
    value: RetentionFunnelStagesEnum.REGULAR_CALLS_AND_MEETINGS,
  },
  {
    label: 'Identification of new business',
    value: RetentionFunnelStagesEnum.IDENTIFICATION_OF_NEW_BUSINESS,
  },
  {
    label: 'Negotiation & Sampling of new business',
    value: RetentionFunnelStagesEnum.NEGOTIATION_AND_SAMPLING_OF_NEW_BUSSINESS,
  },
  {
    label: 'New business first order placed',
    value: RetentionFunnelStagesEnum.NEW_BUSINESS_FIRST_ORDER_PLACED,
  },
];

export const RetentionFunnelOrder = [
  RetentionFunnelStagesEnum.EXISTING_CLIENT,
  RetentionFunnelStagesEnum.REGULAR_CALLS_AND_MEETINGS,
  RetentionFunnelStagesEnum.IDENTIFICATION_OF_NEW_BUSINESS,
  RetentionFunnelStagesEnum.NEGOTIATION_AND_SAMPLING_OF_NEW_BUSSINESS,
  RetentionFunnelStagesEnum.NEW_BUSINESS_FIRST_ORDER_PLACED,
  RetentionFunnelStagesEnum.NOT_INTERESTED,
];

export const RetentionFunnelTransition = {
  [RetentionFunnelStagesEnum.EXISTING_CLIENT]: [
    RetentionFunnelStagesEnum.REGULAR_CALLS_AND_MEETINGS,
    RetentionFunnelStagesEnum.NOT_INTERESTED,
  ],
  [RetentionFunnelStagesEnum.REGULAR_CALLS_AND_MEETINGS]: [
    RetentionFunnelStagesEnum.IDENTIFICATION_OF_NEW_BUSINESS,
    RetentionFunnelStagesEnum.NOT_INTERESTED,
  ],
  [RetentionFunnelStagesEnum.IDENTIFICATION_OF_NEW_BUSINESS]: [
    RetentionFunnelStagesEnum.NEGOTIATION_AND_SAMPLING_OF_NEW_BUSSINESS,
    RetentionFunnelStagesEnum.NOT_INTERESTED,
  ],
  [RetentionFunnelStagesEnum.NEGOTIATION_AND_SAMPLING_OF_NEW_BUSSINESS]: [
    RetentionFunnelStagesEnum.NEW_BUSINESS_FIRST_ORDER_PLACED,
    RetentionFunnelStagesEnum.NOT_INTERESTED,
  ],
};

export const MULTIPLE_ADDRESSES_OBJECT = {
  id: 'multiple-addresses',
  name: 'Multiple Addresses',
  address: 'Multiple Addresses',
  city: 'will be added',
  state: 'as',
  pincode: 'shipping address',
  busyMasterName: 'Multiple Addresses',
  busyMasterCode: 'MULADD',
  billingBusyMasterName: 'Multiple Addresses',
  billingBusyMasterCode: 'MULADD',
};
