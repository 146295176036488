import {
  Avatar,
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FiPlus, FiSearch } from 'react-icons/fi';
import { listChatConversations } from '@/controllers/chat.controller';
import { useAuth } from '@/hooks/AuthContext';
import { useChat } from '@/hooks/ChatContext';
import NewConversationModal from './NewConversationModal';
import NewOrderConversationModal from './NewOrderConversationModal';

export default function ChatConversationsList() {
  const { selectedTab, setSelectedTab } = useChat();

  return (
    <Stack spacing='0' h='full' w='full'>
      <Tabs
        w='full'
        colorScheme='orange'
        size='sm'
        h='full'
        index={selectedTab}
        onChange={index => setSelectedTab(index)}
      >
        <TabList justifyContent='space-around' w='full'>
          <Tab>Direct</Tab>
          <Tab>Orders</Tab>
          {/* <Tab>Requests</Tab> */}
        </TabList>

        <TabPanels p='0' h='calc(100% - 104px)'>
          <TabPanel p='0' h='full'>
            <DirectConversationList />
          </TabPanel>
          <TabPanel p='0' h='full'>
            <OrderConversationList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}

function DirectConversationList() {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchConversations = async () => {
    setLoading(true);

    try {
      const conversations = await listChatConversations('direct');
      setConversations(conversations);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  return (
    <Stack gap='0' p='0' h='full'>
      <HStack p='2'>
        <InputGroup size='sm'>
          <InputLeftElement>
            <FiSearch />
          </InputLeftElement>
          <Input fontSize='sm' variant='filled' placeholder='Search chats' />
        </InputGroup>

        <IconButton
          size='sm'
          variant='ghost'
          icon={<FiPlus />}
          aria-label='New Chat'
          onClick={onOpen}
        ></IconButton>

        <NewConversationModal
          refresh={fetchConversations}
          isOpen={isOpen}
          onClose={onClose}
        />
      </HStack>

      <Stack h='full' overflowY='auto' gap='0'>
        {conversations.length > 0 &&
          conversations.map(conversation => (
            <ChatConversationItem
              key={conversation.id}
              conversation={conversation}
            />
          ))}
        {conversations.length === 0 && !loading && (
          <Stack alignItems='center' p='4'>
            <Text fontSize='sm' color='gray.500' textAlign='center'>
              No chats found
            </Text>

            <Button
              size='xs'
              colorScheme='orange'
              variant='outline'
              onClick={onOpen}
            >
              Start a chat
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

function ChatConversationItem({ conversation }) {
  const chat = useChat();
  const { user } = useAuth();
  const { participants, lastMessage, updatedAt } = conversation;
  const participant = participants.find(p => p.id !== user.id);

  return (
    <HStack
      cursor='pointer'
      px='4'
      py='2'
      _hover={{ bg: 'gray.100' }}
      _active={{ bg: 'gray.200' }}
      onClick={() => chat.setCurrentConversation(conversation)}
      borderBottom='1px solid'
      borderColor='gray.200'
    >
      <Avatar h='36px' w='36px' size='sm' name={participant.name} />

      <Stack gap='0' mx='1'>
        <Text fontSize='sm' fontWeight='medium'>
          {participant.name}
        </Text>
        <Text fontSize='sm' color='gray.500'>
          {!lastMessage ? 'Click to Chat' : lastMessage.message}
        </Text>
      </Stack>

      <Text fontSize='xs' color='gray.500' ml='auto'>
        {moment(updatedAt).fromNow()}
      </Text>
    </HStack>
  );
}

function OrderConversationList() {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchConversations = async () => {
    setLoading(true);

    try {
      const conversations = await listChatConversations('order');
      setConversations(conversations);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  return (
    <Stack gap='0' p='0' h='full'>
      <HStack p='2'>
        <InputGroup size='sm'>
          <InputLeftElement>
            <FiSearch />
          </InputLeftElement>
          <Input fontSize='sm' variant='filled' placeholder='Search chats' />
        </InputGroup>

        <IconButton
          size='sm'
          variant='ghost'
          icon={<FiPlus />}
          aria-label='New Chat'
          onClick={onOpen}
        ></IconButton>

        <NewOrderConversationModal
          refresh={fetchConversations}
          isOpen={isOpen}
          onClose={onClose}
        />
      </HStack>

      <Stack h='full' overflowY='auto' gap='0'>
        {conversations.length > 0 &&
          conversations.map(conversation => (
            <OrderConversationItem
              key={conversation.id}
              conversation={conversation}
            />
          ))}
        {conversations.length === 0 && !loading && (
          <Stack alignItems='center' p='4'>
            <Text fontSize='sm' color='gray.500' textAlign='center'>
              No chats found
            </Text>

            <Button
              size='xs'
              colorScheme='orange'
              variant='outline'
              onClick={onOpen}
            >
              Start a chat
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

function OrderConversationItem({ conversation }) {
  const chat = useChat();
  const { participants, order, lastMessage, updatedAt } = conversation;
  const participant = participants[0];

  return (
    <HStack
      cursor='pointer'
      px='4'
      py='2'
      _hover={{ bg: 'gray.100' }}
      _active={{ bg: 'gray.200' }}
      onClick={() => chat.setCurrentConversation(conversation)}
      borderBottom='1px solid'
      borderColor='gray.200'
    >
      <Avatar h='36px' w='36px' size='sm' name={participant.name} />

      <Stack gap='0' mx='1'>
        <Text fontSize='sm' fontWeight='medium'>
          {order.orderNumber}
        </Text>
        <Text fontSize='sm' color='gray.500'>
          {!lastMessage ? 'Click to Chat' : lastMessage.message}
        </Text>
      </Stack>

      <Text fontSize='xs' color='gray.500' ml='auto'>
        {moment(updatedAt).fromNow()}
      </Text>
    </HStack>
  );
}
