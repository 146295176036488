import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { createContext, useContext, useEffect, useState } from 'react';
import { getQuotationForCart } from '@/controllers/quotation-tool.controller';
import {
  clearCart,
  refetchCart,
  replaceCart,
  updateCart,
} from '../controllers/cart.controller';
import { useAuth } from './AuthContext';
import useSmartToast from './SmartToast';

const CartContext = createContext<CartContextData>(null);

export const useCart = (): CartContextData => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState<CartItemType[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<any>(null);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [quoteURL, setQuoteURL] = useState('');
  const { user, loginStatus } = useAuth();
  const queryClient = useQueryClient();
  const toast = useSmartToast();
  /**
   * Refetch cart
   */
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['refetch-cart', loginStatus, user, selectedVendor],
    queryFn: () =>
      refetchCart({
        vendorId: undefined,
      }),
    enabled: loginStatus === 'LOGGED_IN' && user && user.type === 'client',
  });

  const updateCartItem = useMutation({
    mutationFn: (body: any) => updateCart(body),
    onSuccess: newData => {
      setCart(newData.cart);
      setQuoteURL('');
    },
    onError: error => {
      console.error(error);
      toast.error(error, 'Error updating cart item');
    },
  });

  const replaceAll = useMutation({
    mutationFn: (items: any) => replaceCart(items),
    onSuccess: (newData: any) => {
      setCart(newData.cart);
      setQuoteURL('');
      toast.success('Cart updated successfully');
    },
    onError: error => {
      console.error(error);
      toast.error(error, 'Error updating cart item');
    },
  });

  const clearAll = useMutation({
    mutationFn: () => {
      if (!confirm('Are you sure you want to clear the cart?')) return;
      return clearCart();
    },
    onSuccess: () => {
      setCart([]);
      setQuoteURL('');
    },
    onError: error => {
      console.error(error);
      toast.error(error, 'Error clearing cart');
    },
  });

  const getItemQuantity = (productId: string) => {
    const item = cart.find(item => item.product.id === productId);
    return item ? item.quantity : 0;
  };

  const getQuoteForCart = useMutation({
    mutationFn: getQuotationForCart,
    onSuccess: (data: any) => {
      setQuoteURL(data.pdfUrl);
    },
    onError: error => {
      console.error(error);
      toast.error(error, 'Error generating quote');
    },
  });

  useEffect(() => {
    if (!isLoading && data) {
      setCart(data.cartItems);
      setQuoteURL(data.quoteUrl);
    }
  }, [isLoading, data]);

  if (isError) {
    toast.error(error, error.message || 'Error fetching cart');
    return;
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        quoteURL,
        getQuoteForCart: getQuoteForCart.mutate,
        refetchCart: async () =>
          await queryClient.invalidateQueries({ queryKey: ['refetch-cart'] }),
        addItemToCart: (productId: string) =>
          updateCartItem.mutate({ productId, quantity: 1 }),
        removeItemFromCart: (productId: string) =>
          updateCartItem.mutate({ productId, quantity: 0 }),
        updateCartItem: (productId: string, quantity: number) =>
          updateCartItem.mutate({ productId, quantity }),
        replaceCart: replaceAll,
        clearCart: clearAll.mutate,
        getItemQuantity,
        setIsCartOpen,
        isCartOpen,
        isCartUpdating:
          isLoading ||
          updateCartItem.isPending ||
          replaceAll.isPending ||
          clearAll.isPending ||
          getQuoteForCart.isPending,
        selectedVendor,
        setSelectedVendor,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

interface CartContextData {
  cart: CartItemType[];
  quoteURL: string;
  isCartOpen: boolean;
  isCartUpdating: boolean;
  selectedVendor: any;
  getQuoteForCart: () => void;
  refetchCart: () => void;
  addItemToCart: (product: any) => void;
  removeItemFromCart: (product: any) => void;
  updateCartItem: (productId: string, quantity: number) => void;
  getItemQuantity: (productId: string) => number;
  setIsCartOpen: (value: boolean | ((current: boolean) => boolean)) => void;
  replaceCart: UseMutationResult<any, Error, any, unknown>;
  clearCart: () => void;
  setSelectedVendor: (vendor: any) => void;
}

interface CartItemType {
  product: any;
  quantity: number;
}
