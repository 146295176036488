import {
  Avatar,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import {
  createConversation,
  suggestNewConversation,
} from '@/controllers/chat.controller';
import useSmartToast from '@/hooks/SmartToast';

export default function NewOrderConversationModal({
  refresh,
  isOpen,
  onClose,
}) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const toast = useSmartToast();

  const fetchOrders = async () => {
    setLoading(true);

    try {
      const orderSuggestions = await suggestNewConversation('order');
      setOrders(orderSuggestions.orders);
    } catch (error) {
      toast.error(error.message, 'Failed to fetch users');
    }

    setLoading(false);
  };

  const handleCreate = async () => {
    setCreateLoading(true);

    try {
      await createConversation('order', selectedOrder.orderNumber);
      refresh();
      onClose();
    } catch (error) {
      toast.error(error.message, 'Failed to create conversation');
    }

    setCreateLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalCloseButton />

      <ModalContent>
        <ModalHeader>New Order Conversation</ModalHeader>

        <ModalBody pt='0' mt='0'>
          <Stack>
            <InputGroup size='sm'>
              <InputLeftElement>
                <FiSearch />
              </InputLeftElement>
              <Input
                fontSize='sm'
                variant='filled'
                placeholder='Search users'
              />
            </InputGroup>

            <Stack h='128' overflowY='auto' gap='0'>
              {orders.map(order => (
                <HStack
                  key={order.orderNumber}
                  cursor='pointer'
                  px='4'
                  py='2'
                  borderBottom='1px solid'
                  borderColor='gray.200'
                  transition='all 0.2s ease-in-out'
                  onClick={() => setSelectedOrder(order)}
                  bg={
                    selectedOrder?.orderNumber === order.orderNumber
                      ? 'orange.50'
                      : 'transparent'
                  }
                  color={
                    selectedOrder?.orderNumber === order.orderNumber
                      ? 'orange.500'
                      : 'inherit'
                  }
                  _hover={{
                    bg:
                      selectedOrder?.orderNumber === order.orderNumber
                        ? 'orange.50'
                        : 'gray.100',
                  }}
                >
                  <Avatar h='36px' w='36px' size='sm' name={order.name} />

                  <Stack gap='0' mx='1'>
                    <Text fontSize='sm' fontWeight='medium'>
                      {order.orderNumber}
                    </Text>
                    <Text
                      fontSize='sm'
                      color={
                        selectedOrder?.id === order.orderNumber
                          ? 'orange.500'
                          : 'gray.500'
                      }
                    >
                      {order.status}
                    </Text>
                  </Stack>
                </HStack>
              ))}
            </Stack>
          </Stack>

          <ModalFooter>
            <Button
              variant='procura'
              isLoading={createLoading}
              isDisabled={!selectedOrder}
              onClick={() => {
                handleCreate();
              }}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
