import {
  Box,
  Divider,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Kbd,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { FaCartPlus, FaFileInvoice, FaPhone } from 'react-icons/fa';
import {
  FiBox,
  FiBriefcase,
  FiSearch,
  FiSettings,
  FiShoppingCart,
  FiTag,
  FiUser,
} from 'react-icons/fi';
import { useAuth } from '../../hooks/AuthContext';

export default function QuickSearch() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        onOpen();
      }
    };

    document.addEventListener('keydown', onKeydown);
    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, []);
  return (
    <>
      <InputGroup
        w='64'
        display={{ base: 'none', md: 'flex' }}
        alignItems='center'
        height='16'
        mr='2'
        colorScheme='orange'
      >
        <InputLeftElement pt='6' color='gray.500'>
          <FiSearch />
        </InputLeftElement>
        <Input
          ref={inputRef}
          colorScheme='orange'
          placeholder='Search anything'
          variant='filled'
          height='10'
          fontSize='sm'
          rounded='full'
          flexGrow={1}
          // value={''}
          onClick={onOpen}
          onFocus={e => e.target.blur()}
        />
        <InputRightElement w='20' pt='6'>
          <HStack textColor='gray.500' gap='1'>
            {window.navigator.userAgent.indexOf('Mac') ? (
              <Kbd>⌘</Kbd>
            ) : (
              <Kbd>Ctrl</Kbd>
            )}
            <Kbd>K</Kbd>
          </HStack>
        </InputRightElement>
      </InputGroup>
      {isOpen && <QuickSearchModal isOpen={isOpen} onClose={onClose} />}
    </>
  );
}

function QuickSearchModal({ isOpen, onClose }) {
  const [query, setQuery] = useState<string>('');
  const [focusedIndex, setFocusedIndex] = useState<number>(0);
  const router = useRouter();
  const { user } = useAuth();

  const actions = [
    {
      name: 'View Orders',
      group: 'Orders',
      icon: <FiTag />,
      roles: ['admin', 'client'],
      onExecute: () => {
        router.push('/orders');
      },
    },
    {
      name: 'Create Order',
      group: 'Orders',
      icon: <FiTag />,
      roles: ['admin', 'client'],
      onExecute: () => {
        router.push('/orders/create');
      },
    },
    {
      name: 'View Service Requests',
      group: 'Admin',
      icon: <FiSettings />,
      roles: ['admin'],
      onExecute: () => {
        router.push('/service-requests');
      },
    },
    {
      name: 'View Client Organizations',
      group: 'Clients',
      icon: <FiBriefcase />,
      roles: ['admin'],
      onExecute: () => {
        router.push('/clients');
      },
    },
    {
      name: 'Quotation Maker',
      group: 'Admin',
      icon: <FaFileInvoice />,
      roles: ['admin'],
      onExecute: () => {
        router.push('/tools/quotation');
      },
    },
    {
      name: 'Shop Import',
      group: 'Shop',
      icon: <FiShoppingCart />,
      roles: ['admin'],
      onExecute: () => {
        router.push('/shop-import');
      },
    },
    {
      name: 'Manage Admins',
      group: 'Admin',
      icon: <FiUser />,
      roles: ['admin'],
      onExecute: () => {
        router.push('/admins');
      },
    },
    {
      name: 'My Profile',
      group: 'Client',
      icon: <FiUser />,
      roles: ['client'],
      onExecute: () => {
        router.push('/clients/me');
      },
    },
    {
      name: 'My Products',
      group: 'Client',
      icon: <FiBox />,
      roles: ['client'],
      onExecute: () => {
        router.push('/clients/me/products');
      },
    },
    {
      name: 'My Organization',
      group: 'Client',
      icon: <FiBriefcase />,
      roles: ['client'],
      onExecute: () => {
        router.push('/clients/me/organization');
      },
    },
    {
      name: 'View Order Requests',
      group: 'Order Request',
      icon: <FaCartPlus />,
      roles: ['client'],
      onExecute: () => {
        router.push('/order-requests');
      },
    },
    {
      name: 'Create Order Request',
      group: 'Order Request',
      icon: <FaCartPlus />,
      roles: ['client'],
      onExecute: () => {
        router.push('/order-requests/create');
      },
    },
    {
      name: 'Leads Dashboard',
      group: 'CRM',
      icon: <FaPhone />,
      roles: ['admin'],
      onExecute: () => {
        router.push('/crm/reports');
      },
    },
    {
      name: 'Leads',
      group: 'CRM',
      icon: <FaPhone />,
      roles: ['admin'],
      onExecute: () => {
        router.push('/crm');
      },
    },
  ];

  const results = actions.filter(action => {
    if (query.trim().length == 0) return false;
    if (!user?.type || !action.roles.includes(user?.type)) return false;
    return action.name.toLowerCase().includes(query.toLowerCase());
  });

  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setFocusedIndex(prev => (prev + 1) % results.length);
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setFocusedIndex(prev => (prev - 1 < 0 ? results.length - 1 : prev - 1));
      }
      if (e.key === 'Enter') {
        e.preventDefault();
        results[focusedIndex]?.onExecute();
        onClose();
      }
    };

    document.addEventListener('keydown', onKeydown);
    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, [query, results, focusedIndex]);

  useEffect(() => {
    if (!isOpen) {
      setQuery('');
      setFocusedIndex(0);
    }
  }, [isOpen]);

  const highlightSubstring = (text: string) => {
    const index = text.toLowerCase().indexOf(query.toLowerCase());
    if (index < 0) return text;
    return (
      <>
        {text.substring(0, index)}
        <Box as='span' fontWeight='semibold'>
          {text.substring(index, index + query.length)}
        </Box>
        {text.substring(index + query.length)}
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent p='0' borderRadius='md' bg='transparent' shadow='none'>
        <Box bg='white' rounded='md' shadow='lg' overflow='hidden'>
          <InputGroup>
            <Input
              placeholder='Search anything'
              variant='unstyled'
              px='8'
              h='16'
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
            <InputRightElement color='gray.500' pt='6' fontSize='20' mr='4'>
              <FiSearch />
            </InputRightElement>
          </InputGroup>

          {results.length > 0 && (
            <>
              <Divider mb='0' />
              {results.map((action, index) => (
                <HStack
                  key={action.name}
                  px='4'
                  py='4'
                  alignItems='center'
                  cursor='pointer'
                  _hover={{ color: 'orange.500' }}
                  _focus={{ outline: 'none' }}
                  onFocus={() => setFocusedIndex(index)}
                  onClick={() => {
                    action.onExecute();
                    onClose();
                  }}
                  color='gray.700'
                  {...(focusedIndex === index
                    ? { bg: 'orange.100', color: 'orange.500', outline: 'none' }
                    : {})}
                  as='button'
                  w='full'
                  className='quick-search-result'
                >
                  <Box fontSize='xl'>{action.icon}</Box>
                  <Box fontWeight='regular' fontSize='sm'>
                    {highlightSubstring(action.name)}
                  </Box>
                  <Box
                    style={{ marginLeft: 'auto' }}
                    fontSize='xs'
                    color='gray.500'
                  >
                    {highlightSubstring(action.group)}
                  </Box>
                </HStack>
              ))}
            </>
          )}
        </Box>
      </ModalContent>
    </Modal>
  );
}
